/* eslint-disable max-params */
import {capitalize} from "../../utils/string";

const renderHookImports = (useQuery, useMutation) => {
	if (useQuery && useMutation) {
		return `\nimport { useQuery, useMutation } from 'react-apollo-hooks';\n`;
	}
	if (useQuery) {
		return `\nimport { useQuery } from 'react-apollo-hooks';\n`;
	}
	if (useMutation) {
		return `\nimport { useMutation } from 'react-apollo-hooks';\n`;
	}
	return "";
};
const renderGQLImports = (queries, mutations) => {
	let res = "";
	if (queries.length) {
		res += `// Queries ${queries.map(query => `\nimport { ${capitalize(query)}Query } from '@store/gql/${query}';`).join("")}\n`;
	}
	if (mutations.length) {
		res += `// Mutations ${mutations.map(mutation => `\nimport { ${capitalize(mutation)}Mutation } from '@store/gql/${mutation}';`).join("")}\n`;
	}
	return res;
};

export const renderGraphQLImports = (useQuery, queries, useMutation, mutations) => {
	return `${renderHookImports(useQuery, useMutation) + renderGQLImports(queries, mutations)}${useQuery || useMutation || queries.length || mutations.length ? "\n" : ""}`;
};

const renderQueries = (useQuery, queries) => {
	if (useQuery && !queries.length) {
		return `\n    const { loading, data, error } = useQuery();\n`;
	}
	if (useQuery && queries.length === 1) {
		return `\n    const { loading, data, error } = useQuery(${capitalize(queries[0])}Query);\n`;
	}
	if (useQuery && queries.length) {
		return `${queries.map(query => `\n    const { loading: ${query}Loading, data: ${query}Data, error: ${query}Error } = useQuery(${capitalize(query)}Query);`).join("")}\n`;
	}
	return "";
};
const renderMutations = (useMutation, mutations) => {
	if (useMutation && !mutations.length) {
		return `\n    const [ action, { data: response } ] = useMutation();\n`;
	}
	if (useMutation && mutations.length === 1) {
		return `\n    const [ ${mutations[0]}, { data: response } ] = useMutation(${capitalize(mutations[0])}Mutation);\n`;
	}
	if (useMutation && mutations.length) {
		return `${mutations.map(mutation => `\n    const [ ${mutation}, { data: ${mutation}Response } ] = useMutation(${capitalize(mutation)}Mutation);`).join("")}\n`;
	}
	return "";
};

export const renderGraphQLHook = (useQuery, queries, useMutation, mutations) => {
	return renderQueries(useQuery, queries) + renderMutations(useMutation, mutations);
};
