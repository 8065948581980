export default {
	list: {
		0: {
			id: 0,
			name: "The7Bridges",
			pages: {
				Home: true,
				Info: true,
				Architecture: true,
				"Data Flow": false,
				"Advanced Concepts": false,
				"Copy Tool": false,
				"Extract Tool": false,
				Boilerplate: false,
				Components: false,
				Hooks: false,
				Tests: false,
				"Unit Tests": true,
				Settings: true,
			},
			language: "react",

			libraries: [],
			stateManagement: {
				redux: false,
				graphql: false,
				context: false,
			},
		},
		1: {
			id: 1,
			name: "React",
			language: "react",
			pages: {
				Home: true,
				Info: false,
				Architecture: true,
				"Data Flow": true,
				"Advanced Concepts": false,
				"Copy Tool": false,
				"Extract Tool": false,
				Boilerplate: true,
				Components: true,
				Hooks: true,
				Tests: true,
				"Unit Tests": true,
				Settings: true,
			},
			libraries: {
				"react-router-dom": true,

				"react-redux": false,
				"react-apollo-hooks": true,

				"react-hook-form": true,

				"react-bootstrap": true,
				reactstrap: false,
				"@material-ui/core": false,
				"evergreen-ui": false,
			},
			stateManagement: {
				redux: false,
				graphql: true,
				context: true,
			},
		},
	},
	activeProject: 0,
	order: [0, 1],
};
