/* eslint-disable no-undefined */
import {combineReducers} from "redux";
import projects from "./projects/projects.reducers.js";
import {SET_ACTIVE_PROJECT} from "../actionTypes.js";
import {abilityUpdater} from "../../utils/can/ability.js";

const appReducer = combineReducers({
	projects,
});

const rootReducer = (state, action) => {
	// console.log(state, action);
	if (action.type === "USER_LOGOUT") {
		state = undefined;
	}
	if (action.type === SET_ACTIVE_PROJECT) {
		abilityUpdater(state.projects.list[action.payload]);
	}

	if (action.type === "persist/REHYDRATE" && action.payload) {
		abilityUpdater(action.payload.projects.list[action.payload.projects.activeProject]);
	}

	if (action.type === "persist/REHYDRATE" && !action.payload) {
		// eslint-disable-next-line no-self-assign
		window.location.href = window.location.href;
	}
	return appReducer(state, action);
};

export default rootReducer;
