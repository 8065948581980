import React from "react";
import ContentContainer from "../containers/Content";
import MainLayoutContainer from "../containers/MainLayout";

const AdvancedConceptsPage = () => {
	return (
		<MainLayoutContainer alignItems="stretch" flexDirection="row">
			<ContentContainer title="Advanced Concepts">
				<span />
			</ContentContainer>
		</MainLayoutContainer>
	);
};
export default AdvancedConceptsPage;
