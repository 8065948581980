import {Pane, Paragraph} from "evergreen-ui";
import React from "react";

const DescriptionPrimary = ({content, ...props}) => {
	if (typeof content === "string") {
		return (
			<Paragraph marginBottom={16} {...props}>
				{content}
			</Paragraph>
		);
	}
	return (
		<Pane marginBottom={16} {...props}>
			{content.map(text => (
				<Paragraph key={text}>{text}</Paragraph>
			))}
		</Pane>
	);
};
export default DescriptionPrimary;
