import reactLibraries from "./reactLibraries";
import reactNativeLibraries from "./reactNativeLibraries";
import stateManagement from "./stateManagement";
import pages from "./pages";

const _pages = {};
// eslint-disable-next-line no-return-assign
pages.forEach(page => (_pages[page.title] = true));
export const defaultProject = {
	id: Math.floor(Math.random() * 10000),
	name: "Default",
	language: "react",

	libraries: reactLibraries,
	stateManagement,
	pages: _pages,
};

const newProject = (language = "react") => {
	let libraries;
	if (language === "react") libraries = reactLibraries;
	if (language === "react-native") libraries = reactNativeLibraries;

	return {
		id: Math.floor(Math.random() * 10000),
		name: "New",
		language,

		libraries,
		stateManagement: {
			redux: true,
			graphql: true,
			context: false,
		},
		pages: _pages,
	};
};

export default newProject;
