import {Heading} from "evergreen-ui";
import React from "react";

const HeadingThird = ({text = "", ...props}) => {
	return (
		<Heading fontSize={15} marginBottom={8} marginLeft={5} marginRight={5} marginTop={20} size={500} {...props}>
			{text}
		</Heading>
	);
};
export default HeadingThird;
