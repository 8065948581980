/* eslint-disable react/jsx-newline */
import {Pane, Paragraph} from "evergreen-ui";
import React from "react";
import DescriptionPrimary from "../components/Description/DescriptionPrimary";
import HeadingInfo from "../components/Heading/HeadingInfo";
import HeadingPrimary from "../components/Heading/HeadingPrimary";

// import CodeContainer from "../containers/Code";
import ContentContainer from "../containers/Content";
import MainLayoutContainer from "../containers/MainLayout";
import Can from "../utils/can/Can";

const RenderList = ({items}) => {
	return items.map(item => <RenderListItem key={Math.random()} items={item.items} title={item.title} />);
};

const RenderListItem = ({title, items}) => {
	return (
		<>
			<HeadingInfo fontSize={16} fontWeight={600} text={title} />

			<DescriptionPrimary content={items} marginLeft={16} />
		</>
	);
};

const ArchitecturePage = () => {
	return (
		<MainLayoutContainer alignItems="stretch" flexDirection="row">
			<ContentContainer title="Architecture">
				<Pane flexShrink={0} height="auto" marginBottom={32} paddingTop={16} textAlign="left" width="100%">
					{/*  */}
					<HeadingInfo paddingTop={16} text="Forms" />

					<HeadingPrimary text="Forms" />

					<Paragraph color="muted" marginBottom={16}>
						<b>React Hook Forms</b> for simplicity! Use <b>Yup</b> for handling complex validations!
					</Paragraph>
					{/*  */}
					{/* eslint-disable-next-line react-extra/no-inline-styles */}
					<hr style={{marginTop: 24}} />
					<HeadingInfo paddingTop={16} text="Styles" />

					<HeadingPrimary text="CSS" />

					<Paragraph color="muted" marginBottom={16}>
						Use <b>Sass</b> for handling styling.
					</Paragraph>
					{/*  */}
					{/* eslint-disable-next-line react-extra/no-inline-styles */}
					<hr style={{marginTop: 24}} />
					<HeadingInfo paddingTop={16} text="Testing" />

					<HeadingPrimary text="Unit & Integration Testing" />

					<Paragraph color="muted" marginBottom={16}>
						Use <b>Jest</b> and <b>React Testing Library.</b>
						<br /> Gradually approach switching to <b>Cypress</b> for integration & UI tests (with <b>Chromatic</b>).
					</Paragraph>
					{/*  */}
					{/* eslint-disable-next-line react-extra/no-inline-styles */}
					<hr style={{marginTop: 24}} />
					<HeadingInfo paddingTop={16} text="Internationalization" />

					<HeadingPrimary text="Localization" />

					<Paragraph color="muted" marginBottom={16}>
						Use <b>I18n-next</b> for translations.
						<br /> Use <b>Locize</b> as the translation management editor.
					</Paragraph>
					{/*  */}

					{/* eslint-disable-next-line react-extra/no-inline-styles */}
					<hr style={{marginTop: 24}} />
					<HeadingInfo marginTop={8} paddingTop={16} text="Organization" />
					<HeadingPrimary text="Folder Structure" />

					<Pane marginLeft={16}>
						<RenderList
							items={[
								{title: "/atoms", items: ["- only small components", "- components that will be reusable in lots of places (typography, buttons etc.)"]},
								{title: "/molecules", items: ["- a mixture of small components adding certain customizability", "- can be used on different pages (ex. Table, Navbar)"]},
								{title: "/layout", items: ["- wrapper components (Dashboard Wrapper, ContentContainer)"]},
							]}
						/>

						<HeadingInfo fontSize={16} fontWeight={600} text="/store" />

						{/* <br /> */}

						<HeadingInfo fontSize={14} fontWeight={400} marginLeft={24} text="/redux" />

						<HeadingInfo fontSize={14} fontWeight={400} marginLeft={48} text="/auth" />

						<HeadingInfo fontSize={14} fontWeight={400} marginLeft={72} text="/auth.actions.js" />

						<HeadingInfo fontSize={14} fontWeight={400} marginLeft={72} text="/auth.reducers.js" />

						{/* <br /> */}

						<HeadingInfo fontSize={14} fontWeight={400} marginLeft={24} text="/graphql" />

						<HeadingInfo fontSize={14} fontWeight={400} marginLeft={48} text="/packages" />

						<HeadingInfo fontSize={14} fontWeight={400} marginLeft={72} text="/package.gql" />

						<br />

						<RenderList
							items={[
								{title: "/pages", items: ["- Home, Send, Inventory"]},
								{title: "/routes", items: ["- send"]},
								{title: "/services", items: ["- i18n", "- hooks", "- graphql", "- can/permissions"]},
							]}
						/>

						<Can I="use" on="react">
							{/* <CodeContainer code={sampleHook} handleCopy={handleToggle} /> */}
						</Can>

						<Can I="use" on="react-native">
							{/* <CodeContainer code={sampleHookRN} handleCopy={handleToggle} /> */}
						</Can>
					</Pane>
				</Pane>
			</ContentContainer>
		</MainLayoutContainer>
	);
};
export default ArchitecturePage;
