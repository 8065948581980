/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable no-console */
/* eslint-disable max-statements */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable complexity */
/* eslint-disable react/jsx-handler-names */
import {Button, Dialog, Pane, Tab, TabNavigation, TagInput, TextInput,InlineAlert, Icon, CrossIcon, EyeOpenIcon, CaretRightIcon} from "evergreen-ui";
import React, {useEffect, useReducer,useState} from "react";
import {graphQlQueryToJson} from "graphql-query-to-json";
import CodeEditor from '../../containers/CodeEditor';
import MainLayoutContainer from "../../containers/MainLayout";

import ContentContainer from "../../containers/Content";
import SidebarContainer from "../../containers/Sidebar";
import {unitTestLiteral,renderIndividualUnitTest} from "../literals/UnitTests.literal";

import formReducer from "../../reducers/form.reducer";
import CodeContainer from "../../containers/Code";
import CheckboxPrimary from "../../components/Checkbox/CheckboxPrimary";
import HeadingSecondary from "../../components/Heading/HeadingSecondary";
import HeadingThird from "../../components/Heading/HeadingThird";


const clearedState = {
	copied: false,
	name: "",
	currentTab: "Snapshot",

	react: true,
	"@testing-library/react": false,
	useHistory: false,
	routerProvider:false,
	apolloProvider: false,
	addSubmit: true,

	inputValues: [],
	inputInvalidValues: [],

	unitTests: [],
	modalCode: ``,
};

const initialFormState = {
	copied: false,
	name: "Todos",
	currentTab: "Snapshot",

	react: true,
	"@testing-library/react": false,
	useHistory: false,
	routerProvider:false,
	apolloProvider: false,
	addSubmit: true,

	inputValues: ['firstName', 'lastName'],
	inputInvalidValues: ['email'],

	unitTests: [
		/*
		 * {
		 * 	"code": "\ntest('if it renders renders correctly', () => {\n    const { container } = render(\n        <Router history={history}>\n           <Provider>\n                <Todos />\n            </Provider>\n        </Router>\n    );\n    expect(container).toMatchSnapshot();\n});\n    ",
		 * 	"type": "Snapshot"
		 * },
		 * {
		 * 	"code": "\ntest('navigation on continue', async () => {\n    const historyHook = useHistory();\n    render(\n        <Router history={history}>\n           <Provider>\n                <Todos />\n            </Provider>\n        </Router>\n    );\n    \n    expect(historyHook.push.mock.calls.length).toEqual(0);\n    \n    await act(async () => {\n        await fireEvent.click(screen.getByTestId('continue-btn'));\n    });\n    \n    expect(historyHook.push.mock.calls.length).toEqual(1);\n});\n    ",
		 * 	"type": "Navigation"
		 * },
		 */
		{
			"code": "\ntest('input logic', () => {\n    render(\n        <Router history={history}>\n           <Provider>\n                <Todos />\n            </Provider>\n        </Router>\n    );\n    \n    const firstNameInput = screen.getByTestId('input-firstName');\n    const lastNameInput = screen.getByTestId('input-lastName');\n    \n    expect(firstNameInput.value).toEqual('');\n    expect(lastNameInput.value).toEqual('');\n    \n    fireEvent.change(firstNameInput, { target: { value: 'A' } });\n    fireEvent.change(lastNameInput, { target: { value: 'A' } });\n    \n    expect(firstNameInput.value).toEqual('A');\n    expect(lastNameInput.value).toEqual('A');\n    \n    await act(async () => {\n        await fireEvent.click(screen.getByTestId('form-submit-btn'));\n    });\n    \n    expect(screen.getByTestId('input-email').classList.contains('is-invalid')).toBeTruthy();\n    expect(screen.getByTestId('input-email-error').textContent).toEqual('form.default.required');\n});\n    \n\n// This is just a sample - press Reset to get going!",
			"type": "Forms"
		},
		
		/*
		 *   {
		 *   	"code": "\nconst mockData = {\n    \"query\": \"Todos\",\n    \"variables\": {\n        \"input\": \"\"\n    },\n    \"response\": {}\n};\n\ntest('gql logic', () => {\n    const MutationProvider = MockApolloProvider(mockData);\n\n    const {container} = render(\n        <Router history={history}>\n           <MutationProvider>\n                <Todos />\n            </MutationProvider>\n        </Router>\n    );\n    \n    const firstNameInput = screen.getByTestId('input-firstName');\n    const lastNameInput = screen.getByTestId('input-lastName');\n    \n    fireEvent.change(firstNameInput, { target: { value: 'A' } });\n    fireEvent.change(lastNameInput, { target: { value: 'A' } });\n\n    expect(container).toMatchSnapshot();\n    \n    await act(async () => {\n        await fireEvent.click(screen.getByTestId('form-submit-btn'));\n    });\n    \n    await returnMockGQLResponses();\n    \n    expect(container).toMatchSnapshot();\n});\n    \n\n// This is just a sample - press Reset to get going!",
		 *   	"type": "GQL"
		 *   }
		 */
		 
	],
	modalCode: ``,
};

const UnitTestsPage = () => {
	const [isShown, setIsShown]= useState(false);
	const [formState, dispatch] = useReducer(formReducer, initialFormState);
	const [code,setCode] = useState("");
	const [tempCode, setTempCode] = useState(renderIndividualUnitTest(formState,"Snapshot", {mockApolloProvider: false, mockRouterProvider:false}));
	const [tempGQL, setTempGQL] = useState();
	const [tempJSON, setTempJSON] = useState();
	const [tempGQLError, setTempGQLError] = useState();
	const [tempGQLInputs, setTempGQLInputs] = useState([]);
	const [tempApolloMockProvider, setTempApolloMockProvider] = useState(false);
	const [tempRouterMockProvider, setTempRouterMockProvider] = useState(false);
	const [tempHistoryHookProvider, setTempHistoryHookProvider] = useState(false);
	const [gqlPreview, setGqlPreview] = useState(false);
	const [allInputsPreview, setAllInputsPreview] = useState(false);

	const handleInputChange = e => dispatch({type: "UPDATE", field: e.target.name, payload: e.target.value});
	const handleToggle = e => dispatch({type: "TOGGLE", field: e?.target?.name || "copied"});

	const handleChange = (field, payload) => dispatch({type: "UPDATE", field, payload});

	useEffect(() => {
		let copyTimeout;
		if (formState.copied) copyTimeout = setTimeout(handleToggle, 2000);
		return () => clearTimeout(copyTimeout);
	}, [formState.copied]);

	useEffect(() => {
		setCode(unitTestLiteral(formState));
	}, [formState]);

	const handleGQLConversion = data => {
		const options = {variables:{}};
		let variables = "",values = {},name;
		try{
			variables = data.split("(")[1].split(")")[0].split(",").map(a=>a.trim().split(":")[0].slice(1));
		}catch (e){
			variables=[];
		}
		try{
			if(variables && variables.length) {
				setTempGQLInputs(variables.map(a=>({name: a, placeholder:a,value:""})));
				variables.forEach(a=>{
					options.variables[a]= "";
				});
			}
		} catch(e){
			console.error(e);
		}
		try{
			const jsonSchema = graphQlQueryToJson(data, options);
			// eslint-disable-next-line prefer-destructuring
			values = Object.values(Object.values(jsonSchema)[0])[0];
			
		}catch (e) {
			console.error(e);
		}
		try {
			if(data.includes("mutation")) name = data.split("mutation ")[1].split("(")[0].split("{")[0].trim();
			if(data.includes("query")) name = data.split("query ")[1].split("(")[0].split("{")[0].trim();
		}catch(e){
			console.error(e);
		}
		try{
			const mockProvider = {query: name || "", variables: options.variables};
			delete values.__args;
			mockProvider.response = values;

			setTempJSON(JSON.stringify(mockProvider, null, 4).replaceAll(": true",': ""'));
			setTempCode(renderIndividualUnitTest({...formState, json: JSON.stringify(mockProvider, null, 4).replaceAll(": true",': ""')}, "GQL", {mockApolloProvider: true,mockRouterProvider: tempRouterMockProvider}));
			setTempGQLError();
		} catch(e){
			setTempCode(renderIndividualUnitTest({...formState, json: "{}"}, "GQL", {mockApolloProvider: true,mockRouterProvider: tempRouterMockProvider}));
			setTempGQLError(e.message);
		}
	};
	console.log(111,formState.unitTests);

	return (
		<MainLayoutContainer alignItems="stretch" flexDirection="row">
			<ContentContainer title="Unit Tests" >
				<Pane maxWidth="100%">
					<TextInput autoComplete="off" marginBottom={16} maxWidth="100%" name="name" onChange={handleInputChange} placeholder="Component Name" value={formState.name} />
				</Pane>

				<CodeContainer code={code} copied={formState.copied} handleCopy={handleToggle} setCode={setCode} />

				<Pane alignItems="center" display="flex" justifyContent="center" width="100%"> 

					<Button appearance="minimal" className="d-mobile-none" cursor="unset!important" marginTop={24} onClick={() => dispatch({type: "UPDATE_ALL", payload: clearedState})} opacity={0}>
					Reset
					</Button>

					<Button appearance="minimal" className="default-button" marginTop={24} marginX={16} onClick={() => {
						setIsShown(true);
						setTempCode(renderIndividualUnitTest(formState,formState.currentTab, {mockApolloProvider: tempApolloMockProvider, mockRouterProvider: tempRouterMockProvider}));
					}} paddingX={30}>
						Add Unit Test
					</Button>
					
					<Button appearance="minimal" className="minimal-button" marginTop={24} onClick={() => dispatch({type: "UPDATE_ALL", payload: clearedState})}>
					Reset
					</Button>

				</Pane>

				<HeadingSecondary 
					fontSize={10}
					fontStyle="italic"
					marginTop="auto"
					opacity={0.5}
					paddingTop={30}
					text={(
						<>
						Warning!
							<br/>  

							<br />
						Add unit test cases first and then modify the final results!

							<br /> 

							<br />

						Unit tests are saved on memory, modifying them in this component and then adding a new unit test case <br/>through the form will revert unit test cases on the general code.
						</>
					)} 
					textAlign="left"
					textTransform="none"
				/>
			</ContentContainer>

			<SidebarContainer>
				<HeadingThird marginTop={0} text="Imports" />

				<CheckboxPrimary checked={formState.react} name="react" onChange={handleToggle} />

				<CheckboxPrimary checked={formState["@testing-library/react"]} name="@testing-library/react" onChange={handleToggle} />

				<CheckboxPrimary checked={formState.useHistory} name="useHistory" onChange={handleToggle} /> 
				
				<HeadingThird text="Mocks" />
				
				<CheckboxPrimary checked={formState.routerProvider} name="routerProvider" onChange={(e)=>{
					handleToggle(e);
					setTempRouterMockProvider(e.target.checked);
				}} />

				<CheckboxPrimary checked={formState.apolloProvider} name="apolloProvider" onChange={(e)=>{
					handleToggle(e);
					setTempApolloMockProvider(e.target.checked);
				}} />
				
				<HeadingThird text="Forms" />
				
				<TagInput 
					className="default-tag-input"
					height={30}
					inputProps={{placeholder: "Input Names"}}
					onChange={values => {
						handleChange("inputValues", values);
						setTempCode(renderIndividualUnitTest({...formState, inputValues: values}, formState.currentTab, {mockApolloProvider: tempApolloMockProvider,mockRouterProvider: tempRouterMockProvider}));
					}}
					tagProps={{borderRadius:14}}
					values={formState.inputValues} 
				/>

				<TagInput 
					className="default-tag-input"
					height={30}
					inputProps={{placeholder: "Validate Required Fields"}} 
					onChange={values => {
						handleChange("inputInvalidValues", values);
						setTempCode(renderIndividualUnitTest({
							...formState, 
							inputInvalidValues: values
						}, formState.currentTab, {mockApolloProvider: tempApolloMockProvider,mockRouterProvider: tempRouterMockProvider}));
					}}
					tagProps={{borderRadius:14}}
					values={formState.inputInvalidValues} 
				/>

				<CheckboxPrimary checked={formState.addSubmit} name="addSubmit" onChange={handleToggle} /> 

				{/* <Can I="see" on="graphql"> */}

				{/* 
				<HeadingSecondary text="GraphQL" />

				<CheckboxPrimary checked={formState.useQuery} name="useQuery" onChange={handleToggle} />

				<CheckboxPrimary checked={formState.useMutation} name="useMutation" onChange={handleToggle} /> */}

				{/* </Can> */}

			</SidebarContainer>

			<Pane>
				<Pane>
					<Dialog
						containerProps={{paddingLeft: 30, paddingRight: 30, minHeight:"70%",minWidth: "50%",width:600,maxWidth:"90%", position:"relative"}}
						contentContainerProps={{minHeight:"100%", width:"100%", flexGrow:1, display:"flex",flexDirection:"column", paddingLeft:0, paddingRight:0}}
						footer={(
							<Pane display="flex" flexWrap="wrap" marginTop="auto" maxWidth="100%" position="relative">
								{/* <HeadingSecondary 
									alignSelf="center"
									className="warning-description"
									fontSize={9}
									fontStyle="italic"
									marginBottom={5}
									marginLeft={0}
									marginTop={5}
									opacity={0.5}
									position="absolute"
									text=""
									textTransform="none"
								/> */}

								<Pane alignSelf="flex-end" display="flex" flexGrow={1}>

									<Button appearance="minimal" className="minimal-button" marginLeft="auto" onClick={() => setIsShown(false)}>
									Cancel
									</Button>

									<Button appearance="minimal" className="default-button" marginLeft={16} onClick={() => {
										dispatch({
											type: "UPDATE",
											field: "unitTests",
											payload: [...formState.unitTests, {code: tempCode, type: formState.currentTab}]
										});
		
										setIsShown(false);
									}} paddingX={30}>
									Create
									</Button>
								</Pane>
							</Pane>
						)}
						hasFooter={false}
						header={(
							<Pane display="flex" flexGrow={1} justifyContent="space-between">
								<HeadingThird fontSize={18} marginBottom={0} marginTop={4} text="Add Unit Test" />


								<Icon alignItems="center" color="#234361" cursor="pointer" icon={CrossIcon} justifyContent="center" onClick={()=>setIsShown(false)} width={32} />
							</Pane>
						)}
						isShown={isShown}
						onCloseComplete={()=>setIsShown(false)}
						onConfirm={() => {
							dispatch({
								type: "UPDATE",
								field: "unitTests",
								payload: [...formState.unitTests, {code: tempCode, type: formState.currentTab}]
							});

							setIsShown(false);
						}}
						shouldCloseOnOverlayClick
					>

						<TabNavigation className="TabNavigationPrimary">
							{[
								{id:0, name: "Snapshot"},
								{id:1, name: "UI"},
								{id:2, name: "Navigation"},
								{id:3, name: "Forms"},
								{id:4, name: "GQL"}
							].map(tab => (
								<Tab	
									key={tab.name} 
									background="none!important"
									className="Tab" 
									height={28} 
									is="a" 
									isSelected={formState.currentTab === tab.name} 
									onSelect={() => {
										dispatch({
											type: "UPDATE",
											field: "currentTab",
											payload: tab.name
										});
										if (tab.name==='GQL') return handleGQLConversion(tempGQL);
										return setTempCode(renderIndividualUnitTest(formState,tab.name, {mockApolloProvider: tempApolloMockProvider,mockRouterProvider: tempRouterMockProvider}));
									}}
								>
									{tab.name}							
								</Tab>
							))}
						</TabNavigation>

						{	formState.currentTab=== "GQL" && (
							<Pane marginBottom={26}>
								<HeadingThird marginTop={10} text="GQL to JSON"/>

								<Pane position="relative">

									{gqlPreview ? (
										<CodeEditor
											code={tempJSON}
											language="json"
											maxHeight={200}
											placeholder="...json response will be generated here"
											setCode={setTempJSON}
											// styles={{paddingBottom: 20, maxHeight:200, overflow:"auto!important"}}
											styles={{paddingBottom: 20}}
											value={tempJSON}
										/>
									):(<CodeEditor
										code={tempGQL}
										language="graphql"
										maxHeight={200}
										placeholder="...insert GraphQL Query or Mutation here"
										setCode={(data)=>{
											handleGQLConversion(data);
											setTempGQL(data);
										}}
										// styles={{paddingBottom: 20, maxHeight:200, overflow:"auto!important"}}
										styles={{paddingBottom: 20}}
										value={tempGQL}
									/>)}

	

									<Button 
										appearance="minimal" 
										background="transparent" 
										border={0} 
										bottom={1} 
										boxShadow="unset!important" 
										className="minimal-button" 
										color={!gqlPreview && "#fdfdfd94!important"} 
										onClick={()=>setGqlPreview(!gqlPreview)} 
										outline={0} 
										position="absolute"
										right={1}
									>
		Preview

										<Icon icon={EyeOpenIcon} marginLeft={10} />
									</Button>
								</Pane>

								{tempGQL && tempGQLError && (
									<InlineAlert intent="danger">
										{tempGQLError}
									</InlineAlert>
								)}</Pane>
						)

						}

						<Pane>


							{(formState.currentTab === "Forms" || formState.currentTab === "GQL") && (
								<Pane marginTop={16} >
									<Button alignItems="center" background="transparent!important" border="0 !important" boxShadow="unset!important" 
										display="flex" height={20} marginBottom={8} onClick={()=>setAllInputsPreview(!allInputsPreview)} padding={0}>
										<Icon color="#1E9189" icon={CaretRightIcon} transform={allInputsPreview? "rotate(90deg)": "unset"} />

										<HeadingThird marginLeft={2} marginY={0} text="All Inputs" />
									</Button>
						
									{allInputsPreview&&(	
										<Pane alignItems="flex-start" display="flex"  flexWrap="wrap">
											<TagInput 
												className="default-tag-input"
												height={30}
												inputProps={{placeholder: "Input Names"}}
												onChange={values => {
													handleChange("inputValues", values);
													setTempCode(renderIndividualUnitTest({...formState, inputValues: values}, formState.currentTab, {mockApolloProvider: tempApolloMockProvider,mockRouterProvider: tempRouterMockProvider}));
												}}
												tagProps={{borderRadius:14}}
												values={formState.inputValues} 
											/>

											{formState.currentTab !== "GQL" && (<TagInput 
												className="default-tag-input"
												height={30}
												inputProps={{placeholder: "Validate Required Fields"}} 
												onChange={values => {
													handleChange("inputInvalidValues", values);
													setTempCode(renderIndividualUnitTest({
														...formState, 
														inputInvalidValues: values
													}, formState.currentTab, {mockApolloProvider: tempApolloMockProvider,mockRouterProvider: tempRouterMockProvider}));
												}}
												tagProps={{borderRadius:14}}
												values={formState.inputInvalidValues} 
											/>)}
										</Pane>
									)}

								</Pane>
							)}



							{formState.currentTab === "GQL" ? (
								<>
									
									{/* {tempGQLInputs.map(inputData=> (
										<TextInput key={inputData.name} name={inputData.name} onChange={e=>{
											const updatedInputs = tempGQLInputs.map(a=>{
												if(a.name === inputData.name){
													return {...a, value: e.target.value};
												}
												return a;
											});
											setTempGQLInputs(updatedInputs);
										}} placeholder={inputData.placeholder} value={inputData.value} />
									))} */}


									<HeadingThird marginTop={16} text="Code"/>

									<CodeEditor
										code={tempCode}
										language="javascript"
										placeholder="...unit test will be generated here"
										setCode={setTempCode}
										// styles={{paddingBottom: 20, maxHeight:200, overflow:"auto!important"}}
										styles={{paddingBottom: 20}}
										value={tempCode}
									/>
								</>
							) : (
								<>
									<HeadingThird marginTop={10} text="Code"/>

									<CodeEditor
										code={tempCode}
										setCode={setTempCode}
									/></>
							)}

							<Pane display="flex"  flexWrap="wrap">

								{formState.currentTab === "Forms" && (
									<Pane marginRight={40}>

										<HeadingThird marginTop={16} text="Forms" />

										<CheckboxPrimary checked={formState.addSubmit} name="addSubmit" onChange={e=>{
											handleToggle(e);
											setTempCode(renderIndividualUnitTest({...formState, addSubmit: e.target.checked}, formState.currentTab, {mockApolloProvider: tempApolloMockProvider,mockRouterProvider: tempRouterMockProvider}));
										}} /> 
									</Pane>
								)}

								<Pane marginRight={40}>

									<HeadingThird marginTop={16} text="Mocks" />

									<Pane display="flex">
										<CheckboxPrimary checked={tempRouterMockProvider} name="RouterProvider" onChange={(e)=>{
											setTempRouterMockProvider(e.target.checked);
											setTempCode(renderIndividualUnitTest(formState, formState.currentTab, {mockApolloProvider:tempApolloMockProvider, mockRouterProvider: e.target.checked}));
										}} />

										{formState.currentTab !== "GQL"&& (
											<CheckboxPrimary checked={tempApolloMockProvider} name="ApolloProvider" onChange={(e)=>{
												setTempApolloMockProvider(e.target.checked);
												setTempCode(renderIndividualUnitTest(formState, formState.currentTab, {mockApolloProvider: e.target.checked,mockRouterProvider: tempRouterMockProvider}));
											}} />
										)}

									</Pane>
								</Pane>

								{formState.currentTab === "GQL" &&(<Pane marginRight={40}>

									<HeadingThird marginTop={16} text="Navigation" />

									<Pane display="flex">
										<CheckboxPrimary checked={tempHistoryHookProvider} name="History" onChange={(e)=>{
											setTempHistoryHookProvider(e.target.checked);
											setTempCode(renderIndividualUnitTest(formState, formState.currentTab, {mockApolloProvider:tempApolloMockProvider, mockRouterProvider: tempRouterMockProvider, historyHookProvider: e.target.checked}));
										}} />
									</Pane>
								</Pane>)}
							</Pane>
						

						</Pane>
					</Dialog>
				</Pane>
			</Pane>


		</MainLayoutContainer>
	);
};

export default UnitTestsPage;
