import {Pane} from "evergreen-ui";
import React from "react";
import HeadingPrimary from "../components/Heading/HeadingPrimary";

const ContentContainer = ({title = "", children, ...props}) => {
	return (
		<Pane alignItems="flex-start" display="flex" flex={5} flexDirection="column" marginRight="auto" overflowX="auto" padding={20} paddingTop={35} position="relative" {...props}>
			<HeadingPrimary text={title} />

			{children}
		</Pane>
	);
};

export default ContentContainer;
