/* eslint-disable react/no-unescaped-entities */
import React from "react";
import {Pane, Paragraph} from "evergreen-ui";
import ContentContainer from "../containers/Content";
import MainLayoutContainer from "../containers/MainLayout";
import HeadingPrimary from "../components/Heading/HeadingPrimary";

const InfoPage = () => {
	return (
		<MainLayoutContainer alignItems="stretch" flexDirection="row">
			<ContentContainer title="Info">
				<Pane display="flex" flexDirection="column" width="70%">
					{/* <Pane maxWidth="90%" textAlign="left" width={700}>
					<HeadingInfo paddingTop={16} text="Templating Engine" />
				</Pane> */}

					<Paragraph color="muted" fontSize={12} fontWeight="300" maxWidth="90%" textAlign="left" width={700}>
						Templating Engine
					</Paragraph>

					<HeadingPrimary marginBottom={24} maxWidth="90%" text="Coetic X - 7bridges" textAlign="left" width={700} />

					<Paragraph color="muted" marginBottom={24} maxWidth="90%" textAlign="left" width={700}>
						In today's fast-paced software development landscape, efficiency is paramount. Enter our innovative platform, a sophisticated yet user-friendly templating engine designed to streamline code generation. This powerful tool
						simplifies the often intricate process of coding by allowing users to select their preferred technology stack and input their specific requirements, automatically generating the code they need with just a few clicks.
					</Paragraph>

					<Paragraph color="muted" marginBottom={24} maxWidth="90%" textAlign="left" width={700}>
						One exemplary success story featuring our platform involves our collaboration with 7bridges, a forward-thinking company dedicated to improving unit testing processes. The challenge they faced was the time-consuming nature of
						adding tests to their projects. Our platform came to the rescue by simplifying this daunting task, effectively automating unit testing and drastically reducing the time and effort required. This project, in addition to
						automated code generation, also encompasses essential details like architectural planning, ensuring a comprehensive solution tailored to unique project systems.
					</Paragraph>

					<Paragraph color="muted" marginBottom={24} maxWidth="90%" textAlign="left" width={700}>
						With our templating engine, users have the flexibility to define multiple project systems effortlessly, saving valuable time and resources. By simplifying the code generation process, we empower developers to focus on the
						creative aspects of their projects, accelerating innovation and productivity in the ever-evolving world of software development. Experience the future of coding with our platform and witness firsthand how it revolutionizes the
						way you approach your projects.
					</Paragraph>

					<span />
				</Pane>
			</ContentContainer>
		</MainLayoutContainer>
	);
};
export default InfoPage;
