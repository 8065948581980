/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import {renderHookStateFromForm, renderHookForm, renderHookFormFunctions, renderReactHooksForm, renderReactHooksStateFromForm} from "./form.literal";
import {renderGraphQLHook, renderGraphQLImports} from "./graphql.literal";
import {renderHookState, renderHookUseEffect} from "./default.literal";
import {renderDefaultImports, renderI18nImports, renderReactHookFormImports, renderReduxImports, renderRouterImports} from "./imports.literal";

const renderHistoryAndI18n = (history, i18n) => {
	if (history && i18n) {
		return `\n    const history = useHistory();
    const { t } = useTranslation();\n`;
	}
	if (history) return `\n    const history = useHistory();\n`;
	if (i18n) return `\n    const { t } = useTranslation();\n`;
	return "";
};

export const componentLiteral = data => {
	// eslint-disable-next-line no-unused-vars
	const {
		name,
		withRouter,
		history,
		i18n,
		state,

		useQuery,
		queries,
		useMutation,
		mutations,

		useEffect,
		useEffectArray,

		mapStateToProps,
		mapDispatchToProps,
		connect,
		reduxState,
		reduxActions,

		formText,
		formNumber,
		formSearch,
		formDate,

		loading,
		handleSubmit,

		formTextHook,
		formNumberHook,
		formSearchHook,
		formDateHook,
		handleSubmitHook,
	} = data;

	let exportString = name;
	if (withRouter) exportString = `withRouter(${name})`;
	if (connect) exportString = `connect(${mapStateToProps ? "mapStateToProps" : "null"},${mapDispatchToProps ? "mapDispatchToProps" : "null"})(${exportString})`;

	return `${renderDefaultImports(state, useEffect, formText, formNumber, formSearch, formDate) +
		renderRouterImports(withRouter, history) +
		renderI18nImports(i18n) +
		renderGraphQLImports(useQuery, queries, useMutation, mutations) +
		renderReactHookFormImports(formTextHook, formNumberHook, formSearchHook, formDateHook, handleSubmitHook, loading) +
		renderReduxImports(connect)}
const ${name} = (${withRouter ? `{ match, location, history }` : ""}) => {
${renderHistoryAndI18n(history, i18n) +
	renderGraphQLHook(useQuery, queries, useMutation, mutations) +
	renderHookState(state) +
	renderHookStateFromForm(formText, formNumber, formSearch, formDate, loading) +
	renderReactHooksStateFromForm(formTextHook, formNumberHook, formSearchHook, formDateHook, handleSubmitHook, loading) +
	renderHookUseEffect(useEffect, useEffectArray) +
	renderHookFormFunctions(handleSubmit, formText, formNumber, formSearch, formDate, reduxActions, loading)}
    return (
        <div className="${name}">
            ${name}${renderHookForm(formText, formNumber, formSearch, formDate, handleSubmit, loading)}${renderReactHooksForm(formTextHook, formNumberHook, formSearchHook, formDateHook, handleSubmitHook, loading)}
        </div>
    )
}${
		mapStateToProps || reduxState.length
			? `
const mapStateToProps = ({ ${reduxState.join(", ")} }) => ({ ${reduxState.join(", ")} });`
			: ""
	}${
		mapDispatchToProps || reduxActions.length
			? `
const mapDispatchToProps = {${reduxActions.join(", ")}};`
			: ""
	}

export default ${exportString};`;
};

export const sampleHook = `import React, { useState } from 'react';

const Example = () => {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
}`;
