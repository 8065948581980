import {Pane} from "evergreen-ui";
import React from "react";
/*
 * import SyntaxHighlighter from "react-syntax-highlighter";
 * import {solarizedDark} from "react-syntax-highlighter/dist/esm/styles/hljs";
 */
import {CopyToClipboard} from "react-copy-to-clipboard";
import CodeEditor from "@uiw/react-textarea-code-editor";

const CodeContainer = ({copied, code, setCode, handleCopy, maxHeight = "80vh", language = "javascript"}) => {
	return (
		<Pane alignSelf="stretch" background="blueTint" border="muted" borderRadius={5} fontSize={12} maxHeight={maxHeight} position="relative" textAlign="left">
			<Pane maxHeight="100%" overflowY="scroll">
				<CodeEditor
					language="jsx"
					onChange={evn => setCode(evn.target.value)}
					padding={15}
					placeholder="Please enter JS code."
					// eslint-disable-next-line
					style={{
						fontSize: 12,
						color: "rgb(131, 148, 150)",
						backgroundColor: "#012b36",
						fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
						minWidth: "100%",
						width: "max-content",
						overflowX: "auto",
					}}
					value={code || "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"}
				/>

				{/* <SyntaxHighlighter language={language} showLineNumbers style={solarizedDark}>
					{code || "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"}
				</SyntaxHighlighter> */}
			</Pane>

			{Boolean(handleCopy) && (
				<Pane background="blueTint" borderTopLeftRadius={5} bottom={0} cursor="pointer" padding={5} position="absolute" right={0}>
					{copied ? (
						<span>Copied.</span>
					) : (
						<CopyToClipboard onCopy={handleCopy} text={code}>
							<span>Copy To Clipboard</span>
						</CopyToClipboard>
					)}
				</Pane>
			)}
		</Pane>
	);
};

export default CodeContainer;
