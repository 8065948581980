export default {
	"react-router-dom": true,

	"react-redux": true,
	"react-apollo-hooks": true,

	"react-hook-form": false,

	"react-bootstrap": false,
	reactstrap: false,
	"@material-ui/core": false,
	"evergreen-ui": false,
};
