import {Button, ChevronRightIcon, Icon, Pane} from "evergreen-ui";
import React, {useEffect, useState} from "react";

const SidebarContainer = ({children}) => {
	const mediaQuery = "screen and (min-width: 993px)";
	const mql = window.matchMedia(mediaQuery);
	const [isDesktop, setIsDesktop] = useState(mql.matches);
	const [showSidebar, setShowSidebar] = useState(isDesktop);

	useEffect(() => {
		const handleMediaChange = e => {
			setIsDesktop(e.matches);
			setShowSidebar(e.matches);
		};
		mql.addEventListener("change", handleMediaChange);
		setIsDesktop(mql.matches);

		return () => {
			mql.removeEventListener("change", handleMediaChange);
		};
	}, [mql]);

	return (
		<Pane
			alignItems="flex-start"
			backgroundColor="#fafafa"
			borderLeft="muted"
			bottom={isDesktop ? "unset" : 0}
			display="flex"
			flex={showSidebar ? 2 : 0}
			flexDirection="column"
			flexGrow={1}
			maxWidth="20%"
			minWidth={showSidebar ? 245 : 0}
			paddingBottom={20}
			paddingTop={35}
			paddingX={showSidebar ? 20 : 0}
			position={isDesktop ? "relative" : "fixed"}
			right={isDesktop ? "unset" : 0}
			top={isDesktop ? "unset" : 0}
			width={showSidebar ? "auto" : 0}
		>
			<Button
				alignItems="center"
				background="#fff"
				borderColor="#e5e5e5"
				borderRadius="50%"
				borderWidth="1.5px"
				display="flex"
				height={20}
				justifyContent="center"
				left={showSidebar ? -10 : -32}
				onClick={() => setShowSidebar(!showSidebar)}
				position="absolute"
				top={16}
				transform={showSidebar ? "unset" : "rotate(180deg)"}
				width={20}
			>
				<Icon icon={ChevronRightIcon} paddingLeft={1} size={12} />
			</Button>

			{showSidebar ? children : null}
		</Pane>
	);
};

export default SidebarContainer;
