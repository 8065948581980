import React from "react";
import UIWCodeEditor from "@uiw/react-textarea-code-editor";
import {Pane} from "evergreen-ui";
import CopyToClipboard from "react-copy-to-clipboard";

const CodeEditor = ({copied, code, setCode, handleCopy, styles = {}, maxHeight, ...props}) => {
	return (
		<Pane alignSelf="stretch" background="blueTint" border="muted" borderRadius={5} fontSize={12} maxHeight={maxHeight} overflowY="auto" position="relative" textAlign="left">
			<Pane maxHeight="100%" overflowY="scroll">
				<UIWCodeEditor
					language="jsx"
					onChange={evn => setCode(evn.target.value)}
					padding={15}
					placeholder="Please enter JS code."
					// eslint-disable-next-line
            		style={{
						fontSize: 12,
						color: "rgb(131, 148, 150)",
						backgroundColor: "#012b36",
						fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
						minWidth: "100%",
						width: "max-content",
						...styles,
					}}
					value={code || "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"}
					{...props}
				/>
			</Pane>

			{Boolean(handleCopy) && (
				<Pane background="blueTint" borderTopLeftRadius={5} bottom={0} cursor="pointer" padding={5} position="absolute" right={0}>
					{copied ? (
						<span>Copied.</span>
					) : (
						<CopyToClipboard onCopy={handleCopy} text={code}>
							<span>Copy To Clipboard</span>
						</CopyToClipboard>
					)}
				</Pane>
			)}
		</Pane>
	);
};
export default CodeEditor;
