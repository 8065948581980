import {Heading, Icon, Image, Pane, SidebarTab, TabNavigation} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
// import HeadingSecondary from "../components/Heading/HeadingSecondary";
import pages from "../static/pages";
import {setActiveProject} from "../store/components/projects/projects.actions";

const MainLayoutContainer = ({tabs = pages, projects, setActiveProject, flexDirection = "column", alignItems = "flex-start", children}) => {
	const {pathname} = useLocation();
	const availableTabs = tabs.filter(tab => projects.list[projects.activeProject].pages[tab.title] || tab.title === "Settings");
	// const _projects = Object.values(projects.order).map(projectId => ({label: projects.list[projectId].name, value: projectId}));
	const mediaQuery = "screen and (min-width: 768px)";
	const mql = window.matchMedia(mediaQuery);
	const [isDesktop, setIsDesktop] = useState(mql.matches);

	useEffect(() => {
		const handleMediaChange = e => {
			setIsDesktop(e.matches);
		};
		mql.addEventListener("change", handleMediaChange);
		setIsDesktop(mql.matches);

		return () => {
			mql.removeEventListener("change", handleMediaChange);
		};
	}, [mql]);

	return (
		<Pane background="tint1" borderRadius={3} display="flex" height="100%" minHeight="100vh">
			<Pane background="#fdfdfd" display="flex" flexDirection="column" flexGrow={isDesktop ? 1 : 0} maxWidth="15%" minWidth={isDesktop ? 215 : 55}>
				{/* <Heading is="h5" marginBottom={32} marginTop={32} size={100}>
					dev-tools
				</Heading> */}

				<a href="https://coetic-x.com/" rel="noreferrer" style={{height: 58, justifyContent: isDesktop ? "unset" : "center", display: "flex"}} target="_blank">
					{isDesktop ? <Image padding={15} src="/full-logo.png" width={163} /> : <Image padding={15} src="/logo.png" width={50} />}
				</a>

				<hr className="default-hr" />

				<TabNavigation marginBottom="auto">
					<Heading display={isDesktop ? "flex" : "none"} is="h5" marginBottom={5} marginTop={20} paddingLeft={15} size={100} textAlign="left">
						Menu
					</Heading>

					{availableTabs.map((tab, index) => (
						<SidebarTab
							key={tab.url}
							className="minimal-dark-button"
							/*
							 * disabled={false}
							 * disabled={tab.title !== "Unit Tests"}
							 */
							height={isDesktop ? "auto" : 50}
							// href={tab.title === "Unit Tests" ? tab.url : ""}
							href={tab.url}
							id={tab}
							is="a"
							isSelected={pathname === tab.url}
							onClick={e => tab.url}
							/*
							 * onClick={e => (tab.title === "Unit Tests" ? tab.url : e.preventDefault())}
							 * opacity={tab.title === "Unit Tests" ? 1 : 0.3}
							 */
							paddingLeft={15}
							paddingY={isDesktop ? 4 : 8}
							textAlign="left"
						>
							<Pane paddingRight={9}>{tab.icon && <Icon icon={tab.icon} paddingLeft={isDesktop ? 0 : 2} paddingTop={8} size={isDesktop ? 14 : 20} />}</Pane>

							{isDesktop && <span>{tab.title}</span>}
						</SidebarTab>
					))}
				</TabNavigation>

				{/* eslint-disable-next-line react-extra/no-inline-styles, react/jsx-newline */}
				<a href="https://www.the7bridges.com/" rel="noreferrer" style={{display: "flex", justifyContent: "center"}} target="_blank">
					<Image height={isDesktop ? 100 : 70} padding={15} src="https://assets.website-files.com/5ee251daf876890e806c668f/5ee251daf8768902e96c6715_7bridges%20logomark.svg" />
				</a>

				{/* <Pane alignItems="center" display="flex" flexDirection="column" marginBottom={16}>
					<HeadingSecondary text="Projects" />

					<Pane display="flex" justifyContent="center">
						{_projects.map(({label, value}) => (
							<Text
								key={value}
								color="#1070ca"
								cursor="pointer"
								fontSize={12}
								fontWeight={value === projects.activeProject ? 600 : 300}
								marginBottom={4}
								marginLeft={5}
								marginRight={5}
								marginTop={2}
								onClick={() => setActiveProject(value)}
								size={300}
							>
								{label}
							</Text>
						))}
					</Pane>
				</Pane> */}
			</Pane>

			<Pane alignItems={alignItems} border="muted" display="flex" flex={4} flexDirection={flexDirection} overflowY="scroll">
				{children}
			</Pane>
		</Pane>
	);
};
const mapStateToProps = ({pages, projects}) => ({pages, projects});
const mapDispatchToProps = {setActiveProject};
export default connect(mapStateToProps, mapDispatchToProps)(MainLayoutContainer);
