import React from "react";
import Can from "../../utils/can/Can";
import UnitTests from "./UnitTests";
import UnitTestsRN from "./UnitTestsRN.native";

const TestsPage = () => {
	return (
		<>
			<Can I="use" on="react">
				<UnitTests />
			</Can>

			<Can I="use" on="react-native">
				<UnitTestsRN />
			</Can>
		</>
	);
};
export default TestsPage;
