import {DataLineageIcon, InfoSignIcon, SearchAroundIcon, BarcodeIcon, ApplicationsIcon, ExpandAllIcon, ApplicationIcon, KeyCommandIcon, HorizontalDistributionIcon, LayoutSortedClustersIcon, LocateIcon, SettingsIcon} from "evergreen-ui";

export default [
	// {title: "Home", url: "/", icon: HomeIcon},
	{title: "Info", url: "/info", icon: InfoSignIcon},
	{title: "Architecture", url: "/architecture", icon: SearchAroundIcon},
	{title: "Data Flow", url: "/data-flow", icon: DataLineageIcon},
	{title: "Advanced Concepts", url: "/advanced-concepts", icon: BarcodeIcon},
	{title: "Copy Tool", url: "/copy-tool", icon: ApplicationsIcon},
	{title: "Extract Tool", url: "/extract-tool", icon: ExpandAllIcon},
	{title: "Boilerplate", url: "/boilerplate", icon: ApplicationIcon},
	{title: "Components", url: "/components", icon: KeyCommandIcon},
	{title: "Hooks", url: "/hooks", icon: HorizontalDistributionIcon},
	{title: "Tests", url: "/tests", icon: LocateIcon},
	{title: "Unit Tests", url: "/unit-tests", icon: LayoutSortedClustersIcon},
	{title: "Settings", url: "/settings", icon: SettingsIcon},
];
