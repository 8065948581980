/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
const renderReactImport = react => (react ? `import React from 'react';` : "");
const renderTestingLibraryImport = testingLibraryReact => (testingLibraryReact ? `\nimport { render, fireEvent, waitFor, screen } from '@testing-library/react';` : "");

const renderUserHistoryImport = (react,useHistory, routerProvider) => (useHistory || routerProvider ? `${react ? `\n`: ""}import { ${useHistory && routerProvider? "useHistory, Router": useHistory? "useHistory":"Router"} } from 'react-router-dom';\nimport history from '@routes/history';` : "");
const renderNameImport = name => (name ? `\n\nimport { ${name} } from '../${name}';` : "");
const renderMockApolloProviderImport = name => (name ? `\nimport { MockApolloProvider } from 'common/utils/MockApolloProvider';` : "");

const renderMockApolloProvider = name => (name ? `\n\nconst Provider = MockApolloProvider();\n`:"");

export const unitTestLiteral = data => {
    const {react, "@testing-library/react": testingLibraryReact, useHistory, name, unitTests, routerProvider, apolloProvider} = data;
    
    return `${
        renderReactImport(react) +
        renderTestingLibraryImport(testingLibraryReact) + 
        renderUserHistoryImport(react, useHistory,routerProvider) + 
        renderMockApolloProviderImport(apolloProvider) + 
        renderNameImport(name) + 
        renderMockApolloProvider(apolloProvider)}

${unitTests.map(({code})=>{
    return code;
}).join("")}
`;	
};


const renderIndividualRender = (name, extraData) => {
    const providers = [];
    if(extraData.mockApolloProvider) providers.push("Provider");
    if(extraData.mockRouterProvider) providers.push("Router history={history}");
    let value = `<${name} />`;
    providers.forEach((provider,i)=>{
        value = `${"".padStart((providers.length - i-1)*4,"                                                            ")}<${provider}>
    ${i===0?"".padStart((providers.length)*4+4,"                                                            "):"   "}${value}
    ${"".padStart((providers.length - i)*4,"                                                            ")}</${provider.split(" ")[0]}>`;
    });
    return `render(
        ${value}
    )`;
};
const renderGQLRender = (name, extraData) => {
    const providers = [];
    providers.push("MutationProvider");
    if(extraData.mockRouterProvider) providers.push("Router history={history}");
    let value = `<${name} />`;
    providers.forEach((provider,i)=>{
        value = `${"".padStart((providers.length - i-1)*4,"                                                            ")}<${provider}>
    ${i===0?"".padStart((providers.length)*4+4,"                                                            "):"   "}${value}
    ${"".padStart((providers.length - i)*4,"                                                            ")}</${provider.split(" ")[0]}>`;
    });
    return `const {container} = render(
        ${value}
    )`;
};




const renderIndividualSnapshotTest=(name, extraData)=>{
    return `
test('if it renders renders correctly', () => {
    const { container } = ${renderIndividualRender(name, extraData)};
    expect(container).toMatchSnapshot();
});
    `;
};
const renderIndividualUITest=(name, extraData)=>{
    return `
test('if there is header and body', () => {
    ${renderIndividualRender(name, extraData)};
    
    expect(screen.getByTestId('header-primary', '${name}.header.title')).toBeDefined();
    expect(screen.getByTestId('body')).toBeDefined();
});
    `;
};

const renderIndividualNavigationTest=(name, extraData)=>{
    return `
test('navigation on continue', async () => {
    const historyHook = useHistory();
    ${renderIndividualRender(name, extraData)};
    
    expect(historyHook.push.mock.calls.length).toEqual(0);
    
    await act(async () => {
        await fireEvent.click(screen.getByTestId('continue-btn'));
    });
    
    expect(historyHook.push.mock.calls.length).toEqual(1);
});
    `;
};

const renderIndividualFormsTest=(data, name, extraData)=>{
    return `
test('input logic', () => {
    ${renderIndividualRender(name, extraData)};${data.inputValues?.length ? `
    ${data.inputValues.map(inputName=> (`
    const ${inputName}Input = screen.getByTestId('input-${inputName}');`)).join("")}${data.inputValues.length > 1 ? `
    `:""}${data.inputValues.map(inputName=> (`
    expect(${inputName}Input.value).toEqual('');`)).join("")}
    ${data.inputValues.map(inputName=> (`
    fireEvent.change(${inputName}Input, { target: { value: 'A' } });`)).join("")}${data.inputValues.length > 1 ? `
    `:""}${data.inputValues.map(inputName=> (`
    expect(${inputName}Input.value).toEqual('A');`)).join("")}`:""}
    ${data.addSubmit? `
    await act(async () => {
        await fireEvent.click(screen.getByTestId('form-submit-btn'));
    });
    `:""}${data.inputInvalidValues ? `${data.inputInvalidValues.map(inputName=> (`
    expect(screen.getByTestId('input-${inputName}').classList.contains('is-invalid')).toBeTruthy();`)).join("")}${data.inputInvalidValues.length > 1 ? `
    `:""}${data.inputInvalidValues.map(inputName=> (`
    expect(screen.getByTestId('input-${inputName}-error').textContent).toEqual('form.default.required');`)).join("")}`:""}
});
    `;
};

const renderIndividualGQLTest=(data, name, extraData)=>{
    return `
const mockData = ${data.json || "{}"};

test('gql logic', () => {${extraData.historyHookProvider ? `
    const historyHook = useHistory();`:""}
    const MutationProvider = MockApolloProvider(mockData);

    ${renderGQLRender(name, extraData)};${data.inputValues?.length ? `
    ${data.inputValues.map(inputName=> (`
    const ${inputName}Input = screen.getByTestId('input-${inputName}');`)).join("")}
    ${data.inputValues.map(inputName=> (`
    fireEvent.change(${inputName}Input, { target: { value: 'A' } });`)).join("")}`:""}

    expect(container).toMatchSnapshot();
    ${extraData.historyHookProvider ? `
    expect(historyHook.push.mock.calls.length).toEqual(0);
    `:""}
    await act(async () => {
        await fireEvent.click(screen.getByTestId('form-submit-btn'));
    });
    
    await returnMockGQLResponses();
    ${extraData.historyHookProvider ? `
    expect(historyHook.push.mock.calls.length).toEqual(1);
    `:""}
    expect(container).toMatchSnapshot();
});
    `;
};




export const renderIndividualUnitTest = (data, type, extraData) => {
    // eslint-disable-next-line no-unused-vars
    const {"@testing-library/react": testingLibraryReact, name, unitTests} = data;
    if (type === "Snapshot") {
        return renderIndividualSnapshotTest(name, extraData);
    }
    if(type === "UI"){
        return renderIndividualUITest(name, extraData);
    }
    if(type === "Navigation"){
        return renderIndividualNavigationTest(name, {...extraData, mockRouterProvider:true});
    }

    if(type==="Forms"){
        return renderIndividualFormsTest(data, name, extraData);
    }

    if(type==="GQL"){
        return renderIndividualGQLTest(data, name, extraData);

    }

    return "";




};