import React from "react";
import Can from "../../utils/can/Can";
import Tests from "./Tests";
// import TestsRN from "./Tests.native";

const TestsPage = () => {
	return (
		<>
			<Can I="use" on="react">
				<Tests />
			</Can>

			<Can I="use" on="react-native">
				{/* <TestsRN /> */}
			</Can>
		</>
	);
};
export default TestsPage;
