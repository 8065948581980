/* eslint-disable max-params */
export const renderDefaultImports = (state, useEffect, formText, formNumber, formSearch, formDate) => {
	let extras = "";
	if (state || formText.length || formNumber.length || formSearch.length || formDate.length) {
		extras = ", { useState }";
		if (useEffect) {
			extras = ", { useState, useEffect }";
		}
	} else if (useEffect) {
		extras = ", { useEffect }";
	}

	return `import React${extras} from "react";\n`;
};

export const renderRouterImports = (withRouter, history) => {
	if (history && withRouter) {
		return `import { withRouter } from "react-router";
import { useHistory } from 'react-router-dom';\n`;
	}
	if (history) {
		return `import { useHistory } from 'react-router-dom';\n`;
	}
	if (withRouter) {
		return `import { withRouter } from "react-router";\n`;
	}
	return "";
};

export const renderReduxImports = connect => {
	if (connect) return `import { connect } from "react-redux";\n`;
	return "";
};

export const renderI18nImports = i18n => {
	if (i18n) return `import { useTranslation } from "react-i18next";\n`;
	return "";
};

export const renderReactHookFormImports = (formTextHook, formNumberHook, formSearchHook, formDateHook, handleSubmitHook, loading) => {
	if (!formTextHook.length && !formNumberHook.length && !formSearchHook.length && !formDateHook.length) return "";
	return `import { useForm } from "react-hook-form";\n`;
};
