/* eslint-disable indent */
/* eslint-disable prettier/prettier */
const renderTestingLibrary = testingLibraryReact => (testingLibraryReact ? `import { render, fireEvent, waitFor, screen } from '@testing-library/react';` : "");

const renderNameImport = name => (name ? `\nimport { ${name} } from "../${name}";` : "");

export const testLiteral = data => {
	const {"@testing-library/react": testingLibraryReact, name, testsNumber} = data;
	// console.log(testsNumber);
	return `import React from "react";
${renderTestingLibrary(testingLibraryReact) + renderNameImport(name)}

let container;

beforeEach(() => {
	container = render(<${name} />).container;
})

test('should render ${name}', () => {
	expect(container).toMatchSnapshot();
})

${[...Array(Number(testsNumber)).keys()]
	.map(() => `
test('should ***************', () => {
	
});
`).join("")}

`;
};

export const testExampleLiteral = data => {
	const {"@testing-library/react": testingLibraryReact, name} = data;
	return `import React from "react";
${renderTestingLibrary(testingLibraryReact) + renderNameImport(name)}

let container;

beforeEach(() => {
	const { container: Container } = render(<${name} />);
	container = Container;
})

test('should render ${name}', () => {
	expect(container).toMatchSnapshot();
})

test('should load content', async () => {
	fireEvent.click(screen.getByText('Load content'))

	await waitFor(() => screen.getByRole('heading'))

	expect(screen.getByRole('heading')).toHaveTextContent('Content')

	expect(container).toMatchSnapshot();
})


`;
};